import Vue from 'vue'
import Vuex from 'vuex'
import Contentful from './contentful'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import axios from 'axios';
import Profile from './profile';

Vue.use(Vuex)

const API_URL =  ''//'http://localhost:3000';

export default new Vuex.Store({
  state: {
    profile: {...Profile},
    pages: 6,
    formData: {},
    queryResults: [],
    cfu: {
      information: null,
      inspiration: null,
      programs: null,
      news: null,
      tests: null,
      testIntro: null,
      onboarding: null
    },
    stats: []
  },
  mutations: {
    setInformation(state, data){
      state.cfu.information = data;
    },
    setPrograms(state, data){
      state.cfu.programs = data.map(article => {
        return {
          title: article.fields.title,
          code: article.fields.code,
          programType: article.fields.programtyp,
          shortDesc: article.fields.shortDesc,
          content: documentToHtmlString(article.fields.content),
          media: `https:${article.fields.bild.fields.file.url}`
        }
      })
    },
    setInspiration(state, data){
      state.cfu.inspiration = data.map(article => {
        return {
          title: article.fields.title,
          ingress: article.fields.ingress,
          content: documentToHtmlString(article.fields.content),
          media: article.fields.media.fields.file
        }
      });
    },
    setNews(state, data){
      state.cfu.news = data;
    },
    setTests(state, data){
      state.cfu.tests = data;
    },
    setIcebergResult(state, data){
      data.program.forEach(program => {
        state.profile.iceberg[program].value++;
      })
      updateLocalStorage(state.profile);
    },
    setGardnerResult(state, data){
      state.profile.gardner[data.intelligence[0]].value += data.value;
      updateLocalStorage(state.profile);
    },
    setQuizResult(state, data){
      state.profile.quiz[data.question*1].value = data.value*1;
      updateLocalStorage(state.profile);
    },
    setTestIntro(state, data){
      state.testIntro = data.map(article => {
        return {
          title: article.fields.rubrik,
          content: documentToHtmlString(article.fields.contents)
        }
      })
    },
    setOnboarding(state, data){
      state.cfu.onboarding = data.map(article => {
        return {
          title: article.fields.title,
          content: documentToHtmlString(article.fields.content)
        }
      })
    },
    tellMeMore(state, title){
      if(state.profile.tellMeMore.filter(item => item == title).length < 1){
        state.profile.tellMeMore.push(title);
        updateLocalStorage(state.profile);
      } else {
        let index = state.profile.tellMeMore.indexOf(title);
        state.profile.tellMeMore.splice(index, 1);
        updateLocalStorage(state.profile);
      }
    },
    setFormData(state, formData){
      state.formData = formData.data;
    },
    setQueryResults(state, queryResults){
      state.queryResults = queryResults;
    },
    resetProfile(state){ 
      /*
      state.profile.gardner.map(entry => entry.value === 0)
      state.profile.iceberg.map(entry => entry.value === 0)
      state.profile.quiz.map(entry => entry.value === 0)
      */
      state.profile = {...Profile};
      state.tellMeMore = [];
      localStorage.removeItem('konrad')
    }
  },
  actions: {
    async sendQuery(ctx, query){

      let results = await axios.post(`${API_URL}/query`, { school: query.school, program: query.program, municipality: query.municipality, meritValue: query.meritValue }); 
      ctx.commit('setQueryResults', results.data)

    },
    async getCfuData(ctx){
      
      let data = await Contentful.getEntries();  

      ctx.commit('setInformation', data.items.filter(item => item.sys.contentType.sys.id == 'information'));
      ctx.commit('setPrograms',    data.items.filter(item => item.sys.contentType.sys.id == 'program'));
      ctx.commit('setInspiration', data.items.filter(item => item.sys.contentType.sys.id == 'inspiration'));
      ctx.commit('setNews',        data.items.filter(item => item.sys.contentType.sys.id == 'news'));
      ctx.commit('setTests',       data.items.filter(item => item.sys.contentType.sys.id == 'tests'));
      ctx.commit('setTestIntro',   data.items.filter(item => item.sys.contentType.sys.id == 'testintro'));
      ctx.commit('setOnboarding',   data.items.filter(item => item.sys.contentType.sys.id == 'onboarding'));
    },
    async getFormData(ctx){

      let results = await axios.get(`${API_URL}/formdata`);
      ctx.commit('setFormData', results)
    },
    async contact(ctx, payload) {
      
      try {
        let profile = null;

        if(payload.profile){
          profile = ctx.state.profile
        }

        await axios.post(`${API_URL}/contact`, { profile: profile, ...payload })
      } catch(err) {
        console.error(err)
      }
    }
  },
  getters: {
    information(state){
      return state.cfu.information.filter(art => art.fields.content);
    },
    inspiration(state){
      return state.cfu.inspiration;
    },
    getInsprationArticleId: (state) => (id) => {
      return state.cfu.inspiration[id-1];
    },
    programInfo: (state) => (code) => {
      return state.cfu.programs.filter(prg => prg.code === code)[0];
    },
    programs(state){
      return state.cfu.programs.sort(sort);
    },
    gardner(state){
      return {
        questions: state.cfu.tests.filter(test => test.fields.title == 'Gardner')[0].fields.data.questions,
        desc: documentToHtmlString(state.cfu.tests.filter(test => test.fields.title == 'Gardner')[0].fields.desc),
        potentials: state.cfu.tests.filter(test => test.fields.title == 'Gardner')[0].fields.data.potentials
      }
    },
    iceberg(state){ 
      return {
        questions: state.cfu.tests.filter(test => test.fields.title == 'Isberget')[0].fields.data.questions,
        desc: documentToHtmlString(state.cfu.tests.filter(test => test.fields.title == 'Isberget')[0].fields.desc)
      }
    },
    quiz(state){
      return {
        questions: state.profile.quiz
      }
    },
    profile(state){
      return {
        gardner: state.profile.gardner.map(intelligence => intelligence.value*1 / 45),
        iceberg: state.profile.iceberg.sort((a, b) => b.value - a.value),
        quiz: state.profile.quiz.sort((a, b) => b.value - a.value)
      }
    },
    testIntro(state){
      return state.testIntro;
    },
    onboarding(state) {
      return state.cfu.onboarding[0];
    }
  }
})

function sort(a, b)
{
   if (a.title < b.title)
      return -1;
   if (a.title > b.title)
      return 1;
   return 0;
}

function updateLocalStorage(profile){
  localStorage.setItem('konrad', JSON.stringify(profile));
}