<template>
  <div id="app" ref="top">
    <aside id="totop" @click.prevent="scrollToAnchorPoint('top')">
      <svg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'><title>Arrow Up</title><path fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='48' d='M112 244l144-144 144 144M256 120v292'/></svg>
    </aside>
    <transition name="reveal">
      <info v-if="showInfo" />
    </transition>
    <transition :name="transitionType">
      <router-view @move="moveTo" />
    </transition>
  </div>
</template>
<script>
import info from '@/components/Info';

export default {
  name: 'App',
  components: {
    info
  },
  data(){
    return {
      showInfo: false,
      duration: 400,
      transitionType: ''
    }
  },
  beforeMount(){
    this.$store.dispatch('getCfuData');
    this.$store.dispatch('getFormData');

    window.onresize = function() {
      document.body.height = window.innerHeight;
    }
    
    window.onresize();

  },
  methods: {
    scrollToAnchorPoint(refName) {
      let el = this.$refs[refName];
      el.scrollIntoView({ behavior: 'smooth'})
    },
    moveTo(e){
      this.transitionType = e.transitionType;
      this.$router.push(e.to);
    }
  }
}
</script>

<style lang="scss">
@import 'scss/main';

.next-leave-active,
.next-enter-active {
  transition: .3s;

  #app {

    > main {
      position: absolute;
    }
  }
}

#totop {
  display: flex;
  align-items: center;
  position: fixed;
  width: 2.4rem;
  height: 3rem;
  right: 1rem;
  bottom: 1rem;
  border: .125rem solid black;
  border-radius: 4px;
  z-index: 999;
  opacity: .6;

  svg {
    width: 100%;
  }
}

.next-enter {
  transform: translate(100%, 0);
}

.next-leave-to {
  transform: translate(-100%, 0);
}

.prev-leave-active,
.prev-enter-active {
  transition: .3s;
}

.prev-enter {
  transform: translate(-100%, 0);
}

.prev-leave-to {
  transform: translate(100%, 0);
}


.reveal-leave-active,
.reveal-enter-active {
  transition: .3s;
}

.reveal-enter {
  transform: scale(1.1);
  opacity: 0;
  filter: blur(10px);
}

.reveal-leave-to {
  transform: scale(1);
  opacity: 1;
}

.start-leave-active,
.start-enter-active {
  transition: .3s;
}

.start-enter {
  transform: translate(0, 100%);
}

.start-leave-to {
  transform: translate(0, -100%);
}

.end-leave-active,
.end-enter-active {
  transition: .3s;
}

.end-enter {
  transform: translate(0, -100%);
}

.end-leave-to {
  transform: translate(0, 100%);
}

</style>