<template>
    <section id="globalinfo">
        <aside>
            <a href="#" @click="close">stäng</a>
        </aside>
        <h1>Hej!</h1>
        <p class="ingress">Konrad är en app som hjälper dig som går i årskurs 8 eller 9 att välja gymnasiet.</p>
        <h2>Lägg till på hemskärmen</h2>
        <p>Konrad är en sk. progressiv webbapp och fungerar bäst om du lägger till den på hemskärmen. Den ser då ut och beteer sig som en helt vanlig mobilapp.</p>
        <p>Hur du gör det hittar du <a href="https://www.youtube.com/watch?v=DBOR8mfdVZ8" target="_blank" >här</a> ( iOS ) och <a href="https://www.youtube.com/watch?v=kaXWohkYy0A" target="_blank">här</a> ( Android ).</p>
        <h2>Tillgänglighet</h2>
        <p>Utbildningsförvaltningen Mölndals stad är ansvarig för "Konrad" vars målgrupp är elever som läser årskurs 8 och 9 och skall få mer information om sitt kommande gymnasieval. Vi vill att så många som möjligt ska kunna använda webbplatsen. Vi arbetar löpande med att förbättra Konrad/webbplatsens tillgänglighet. Lämna gärna dina synpunkter om du upptäcker problem och brister som hindrar dig från att ta del av vår digitala service.</p> 
        <p>Skicka ett e-postmeddelande till: <b>utf.support@molndal.se</b></p>
        <h2>GDPR</h2>
        <p>Konrad sparar dina testresultat lokalt i webbläsaren i sk. localStorage. Det betyder att ingen annan kommer åt din data ( svarsresultat på testen). Du väljer själv om du sedan vill dela denna data ( med förslagsvis din SYV).</p> 
        <p><label for="gdpr" @click="gdpr = !gdpr"><input type="checkbox" :checked="gdpr" name="gdpr"><span>Konrads data behandlas i enlighet med <a href="https://www.molndal.se/startsida/kommun-och-politik/insyn-och-paverkan/personuppgifter/hantering-av-personuppgifter-inom-utbildningsforvaltningen.html" target="_blank">Utbildingsförvaltningen i Mölndals stads personuppgiftspolicy.</a></span></label></p>
        <p>Vill du rensa den data som är sparad så klicka nedan.</p>
        <a href="#" @click="emptyLocalStorage" class="btn" :class="{ empty : isEmpty } ">Ta bort all lokal data</a>
    </section>
</template>

<script>
export default {
    name: 'info',
    data(){
        return {
            isEmpty: false
        }
    },
    methods: {
        emptyLocalStorage(){
            if(confirm('Är du säker på att du vill ta bort all lokal data? Detta går inte att ångra.')){
                // Remove 
                localStorage.removeItem('vuex');
                localStorage.removeItem('konradmodal');
                this.isEmpty = true;
            }
        },
        close(){
            this.$parent.showInfo = false;
        }
    }
}
</script>

<style lang="scss">
@import '../scss/variables';

#globalinfo {
    position: fixed;
    padding: 4rem 1rem 1rem 1rem;
    box-sizing: border-box;
    width: 94vw;
    height: 80vh;
    margin: 10vh 3vw;
    background: rgba($color: $red, $alpha: 1);
    color: white;
    z-index: 100;
    box-shadow: 0 0 2rem rgba($color: black, $alpha: .4);
    border-radius: $radius;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

 > aside {
        display: flex;
        align-items: center;
        height: 3rem;

        a {
            position: absolute;
            top: 1rem;
            left: 1rem;
            text-decoration: none;
            padding: .25rem .5rem;
            border-radius: $radius;
            font-size: .8rem;
            border: 1px solid white;
            color: white;
        }
    }


    p {
        color: rgba($color: white, $alpha: .8);

        &.ingress {
            color: rgba($color: white, $alpha: .96);
            font-weight: 600;
            line-height: 1.4rem;
        }


        a {
            color: white;
            display: inline-block;
            font-weight: 700;
        }
    }

    
    h2 {
        font-size: 1.2rem;
        margin: 1.2rem 0 .3rem 0;
        line-height: 1.6rem;
        color: rgba($color: white, $alpha: .96);
    }

    .btn {
        border: 1px solid white;
        color: white;

        &.empty {
            opacity: .4;
        }
    }
}
</style>
