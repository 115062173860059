import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
//  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('./views/Home.vue')
    },

    // Test
    {
      path: '/test',
      name: 'test',
      component: () => import('./views/Tests.vue')
    },

    // Gardner
    {
      path: '/test/personlighetstestet',
      name: 'gardner',
      component: () => import('./components/tests/Gardner.vue')
    },
    {
      path: '/test/personlighetstestet/fragor',
      name: 'questions',
      component: () => import('./components/tests/gardner/Questions.vue')
    },
    {
      path: '/test/personlighetstestet/resultat',
      name: 'resultat',
      component: () => import('./components/tests//gardner/Results.vue')
    },
    
    // Isberget
    {
      path: '/test/programtestet',
      name: 'iceberg',
      component: () => import('./components/tests/Iceberg.vue')
    },
    {
      path: '/test/programtestet/fragor',
      component: () => import('./components/tests/iceberg/Questions.vue')
    },
    {
      path: '/test/programtestet/resultat',
      component: () => import('./components/tests/iceberg/Results.vue')
    },

    // Quiz
    {
      path: '/test/valpaverkan',
      name: 'quiz',
      component: () => import('./components/tests/Quiz.vue')
    },
    {
      path: '/test/valpaverkan/fragor',
      name: 'quizquestions',
      component: () => import('./components/tests/quiz/Questions.vue')
    },
    {
      path: '/test/valpaverkan/resultat',
      name: 'quizresults',
      component: () => import('./components/tests/quiz/Results.vue')
    },

    // Information
    {
      path: '/hej',
      name: 'onboarding',
      component: () => import('./views/Onboarding.vue')
    },

    // Information
    {
      path: '/information',
      name: 'information',
      component: () => import('./views/Information.vue')
    },

    // Program
    {
      path: '/information/program/:program',
      name: 'fullprogramarticle',
      component: () => import('@/components/articles/fullProgramArticle.vue')
    },

    // Inspiration
    {
      path: '/inspiration',
      name: 'inspiration',
      component: () => import('./views/Inspiration.vue')
    },
    {
      path: '/inspiration/:id',
      name: 'inspirationarticle',
      component: () => import('./components/articles/FullArticle.vue')
    },
    {
      path: '/information/results',
      name: 'results',
      component: () => import('./components/Results.vue')
    },

    // Kontakt
    {
      path: '/kontakt',
      name: 'contact',
      component: () => import('./views/Contact.vue')
    },

    // Studieprofil
    {
      path: '/studieprofil',
      name: 'profile',
      component: () => import('./components/Studyprofile.vue')
    }
  ]
})
