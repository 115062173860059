export default {
    gardner: [
      { title: 'fysisk/mekanisk', value: 0 },
      { title: 'estetisk', value: 0 },
      { title: 'logisk/analys', value: 0 },
      { title: 'språklig', value: 0 },
      { title: 'social', value: 0 }
    ],
    iceberg: [
      { short: 'BA', title: 'Bygg- & anläggnigsprogrammet', value: 0 },
      { short: 'BF', title: 'Barn- & fritidsprogrammet', value: 0 },
      { short: 'EE', title: 'El- och energiprogrammet', value: 0 },
      { short: 'EK', title: 'Ekonomiprogrammet', value: 0 },
      { short: 'ES', title: 'Estetiska programmet', value: 0 },
      { short: 'FT', title: 'Fordons- och transportprogrammet', value: 0 },
      { short: 'FS', title: 'Försäljning- och serviceprogrammet', value: 0 },
      { short: 'HV', title: 'Hantverksprogrammet', value: 0 },
      { short: 'HT', title: 'Hotell & Turismprogrammet', value: 0 },
      { short: 'HU', title: 'Humanistiska programmet', value: 0 },
      { short: 'IN', title: 'Industritekniska programmet', value: 0 },
      { short: 'NA', title: 'Naturvetenskapsprogrammet', value: 0 },
      { short: 'NB', title: 'Naturbruksprogrammet', value: 0 },
      { short: 'RL', title: 'Resturang- & livmedelsprogrammet', value: 0 },
      { short: 'SA', title: 'Samhällsvetenskapsprogrammet', value: 0 },
      { short: 'TE', title: 'Teknikprogrammet', value: 0 },
      { short: 'VF', title: 'VVS och fastighetsprogrammet', value: 0 },
      { short: 'VO', title: 'Vård och omsorgsprogrammet', value: 0 }
    ],
    quiz: [
      { text: 'Hälsa', value: 0 },
      { text: 'Förmågor/Anlag', value: 0 },
      { text: 'Värderingar', value: 0 },
      { text: 'Intressen', value: 0 },
      { text: 'Självkännedom', value: 0 },
      { text: 'Personlighet', value: 0 },
      { text: 'Fysiska egenskaper', value: 0 },
      { text: 'Kunskap om yrken', value: 0 },
      { text: 'Erfarenheter', value: 0 },
      { text: 'Min ålder', value: 0 },
      { text: 'Motivation', value: 0 },
      { text: 'Studieteknik / Lärstil', value: 0 },
      { text: 'Om jag är praktisk eller teoretisk', value: 0 },
      { text: 'Ekonomi', value: 0 },
      { text: 'Vart jag bor', value: 0 },
      { text: 'Krav och behörigheter från skolor', value: 0 },
      { text: 'Arbetsmarknad', value: 0 },
      { text: 'Politiska beslut', value: 0 },
      { text: 'Media ( tv, tidningar, radio )', value: 0 },
      { text: 'Sociala medier', value: 0 },
      { text: 'Familj', value: 0 },
      { text: 'Vänner', value: 0 },
      { text: 'Andra viktiga personer (tränare, lärare, etc)', value: 0 }
    ],
    tellMeMore: []
  }